export default {
  'dev': {
    //开发环境
    SOCKET_API: "wss://mg.100api.cn/chat",
    BASE_API: "https://mg.100api.cn/middle-im"
  },
  'test': {
    //测试环境
    SOCKET_API: "wss://mg.test.100api.cn/chat",
    BASE_API: "https://mg.test.100api.cn/middle-im"
  },
  'prod': {
    //线上环境
    SOCKET_API: "wss://m-platform.100api.cn/chat",
    BASE_API: "https://m-platform.100api.cn/middle-im"
  }
}