import ProtoRoot from '../proto/proto'

const rawHeaderLen = 16
const packetOffset = 0 // 包长的偏移量
const headerOffset = 4 // 头长的偏移量
const verOffset = 6 // 版本的偏移量
const opOffset = 8 // 指令的偏移量
const seqOffset = 12 // 序列的偏移量
const opHeartbeat = 3 // 心跳请求
const messageRes = ProtoRoot.lookup('message.Raw')

let env = 'prod' //接口的环境参数
export default {
  rawHeaderLen,
  packetOffset,
  headerOffset,
  verOffset,
  opOffset,
  seqOffset,
  opHeartbeat,
  messageRes,
  env
}