const IM_EVENT = {
    USER_JOIN : 'userJoin',  //加入房间
    USER_LEAVE: 'userLeave', //离开房间
    USER_TIPS: 'userTips', //用户加入或者离开tips
    KICKED_OUT_RECEIVED: 'kickedOutReceived', //被踢出房间回调
    TEXT_MESSAGE_RECEIVED: 'textMessageReceived', //文本消息回调
    IMG_MESSAGE_RECEIVED: 'imgMessageReceived', //图片消息回调
    SOCKET_CONNECT_FAIL: 'socketConnectFail', //socket连接失败
    ROOM_HEAT: 'roomHeat',  //获取房间热度
    MANAGER_GROUP_SEND_MESSAGE_RECEIVED: 'managerGroupSendMessageReceived' //管理员群发消息回调
}
export default IM_EVENT