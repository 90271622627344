import { create, createJoinRoom } from '../util/request'
import Env from "../util/env";
import global from "../util/global";

/**
 * 加入房间
 * @param data
 */
export function joinRoom (data) {

  let apiBaseUrl = getBaseUrl()

  return create(apiBaseUrl)({
    url: '/live/join',
    method: 'post',
    params: data
  })

}

/**
 * 发送消息
 */
export function sendMsg (roomId, data) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/message/`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

/**
 * 发送消息(私聊)
 * target:从用户列表获取的用户token
 */
export function sendPrivateMsg (roomId, target, data) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/${target}/message/`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

/**
 * 获取聊天室用户列表
 * @param roomId
 */
export function getUserList (roomId) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/users/`,
    method: 'get',
    params: {
    }
  })
}

/**
 * 获取房间信息
 * @param roomId
 */
export function getRoomInfo (roomId) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}`,
    method: 'get',
    params: {
      room_id: roomId
    }
  })
}

/**
 * 获取房间热度
 * @param roomId
 */
export function getRoomHeat (roomId) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/heat`,
    method: 'get',
    params: {
      room_id: roomId
    }
  })
}

/**
 * 获取聊天记录
 * @param roomId
 */
export function getRoomHistory (roomId, start, end, page, size) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/history`,
    method: 'get',
    params: {
      room_id: roomId,
      start: start,
      end: end,
      page: page,
      size: size
    }
  })
}

/**
 * 更新房间热度
 * @param roomId
 */
export function updateRoomHeat (roomId, data) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/room/${roomId}/heat`,
    method: 'put',
    data: data
  })
}

/**
 * 更新昵称和头像
 * @param data
 * @returns {*}
 */
export function updateNickAndAvatar (data) {
  let apiBaseUrl = getBaseUrl()
  return create(apiBaseUrl)({
    url: `/live/personal`,
    method: 'put',
    data: data
  })
}

function getBaseUrl () {
  const apiBaseUrl = Env[global.env]['BASE_API']
  return apiBaseUrl
}